
import { queryAgentInfo, queryRechargeLog, queryUserList } from "@/service/apiService";
import { Vue, Component } from "vue-property-decorator";

@Component({
  components: {},
})
export default class UserView extends Vue {
  private agentInfo: any = {
    totalUserNum: 0,
    totalDeviceNum:  0,
    memberCount:  0,
    leftCount: 0,
    invitationCode: '',
  }

  private rechargePageList: Array<any> = []

  private userList: Array<any> = []

  private dateTime: Array<string> = []

  private rechargeQueryParm: Record<string,any> = {
    userId: 0,
    page: 1,
    size: 20,
    start: null,
    end: null
  }

  private dateChange() {
    if (this.dateTime && this.dateTime.length > 1) {
      this.rechargeQueryParm.start = this.dateTime[0]
      this.rechargeQueryParm.end = this.dateTime[1]
    }
    this.rechargeQueryParm.page = 1
    this.getRechargeLogList()
  }


  private mounted() {
    this.getAgentInfo()
    this.getRechargeLogList()
    this.getAllUserList()
  }

  private getRechargeLogList() {
    queryRechargeLog(
      this.rechargeQueryParm.userId,
      this.rechargeQueryParm.page,
      this.rechargeQueryParm.size,
      this.rechargeQueryParm.start,
      this.rechargeQueryParm.end,
    ).then(rep => {
      this.rechargePageList = rep.data.list
    })
  }

  private getAgentInfo() {
    queryAgentInfo().then(rep => {
      this.agentInfo = rep.data
    }).catch(() => {})
  }

  private getAllUserList() {
    queryUserList('',1, 1000).then(rep => {
      this.userList = rep.data.list
    }).catch(() => {})
  }

  private selectUserChange() {
    this.rechargeQueryParm.page = 1
    this.getRechargeLogList()
  }

  private handleSizeChange(val: number) {
    this.rechargeQueryParm.size = val
    this.getRechargeLogList()
  }

  private handleCurrentChange(val: number) {
    this.rechargeQueryParm.page = val
    this.getRechargeLogList()
  }
}
